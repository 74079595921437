











































































































import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
import {
    mdiNumeric1Circle,
    mdiNumeric2Circle,
    mdiNumeric3Circle,
} from "@mdi/js";
import SupportEmailLink from "@/components/SupportEmailLink.vue";
import ContactUsLink from "@/components/ContactUsLink.vue";
@Component({
    components: { ContactUsLink, SupportEmailLink, BackButton },
})
export default class FaqWhyWasINotReconnectedAfterSuccessfullPayment extends Vue {
    oneIcon: string = mdiNumeric1Circle;
    twoIcon: string = mdiNumeric2Circle;
    threeIcon: string = mdiNumeric3Circle;
}
